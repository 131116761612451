<script setup lang="ts">
import useDark from '~/composables/useDark.ts';

useDark();
</script>

<template>
    <main class="container pt-5 pb-3">
        <slot></slot>
    </main>
</template>
